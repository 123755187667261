<template>
  <KioskSlide title="Carbon Emissions" :sub-title="`Carbon emissions summary for ${kiosk.asset.siteName}`" :hide="hideSlide">
    <div class="p-5 border-bottom">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Past Week</div>
          <div class="font-w700 display-3 mb-4">
            {{ currentWeekUsage | numberFormat }} <span class="font-w600 font-size-h1">kg CO<sub>2</sub>e</span>
          </div>
          <PctDiff :usage="currentWeekUsage" :prev-usage="prevWeekUsage">Compared to previous week</PctDiff>
          <div class="font-size-h4 font-w500 text-muted">
            This is similar to driving a petrol car <strong>{{ (currentWeekUsage / 100) | numberFormat(1) }} miles</strong> or boiling the kettle
            <strong>{{ (currentWeekUsage / 0.05) | numberFormat }} times</strong>.
          </div>
        </div>
        <div class="col">
          <AssetWeeklyConsumption
            style="height: 325px"
            :period="weekGraph.period"
            :data="consumption.currentHH.data"
            units="CO2e"
            weekdays
            compare
            emissions
          />
        </div>
      </div>
    </div>
    <div class="p-5">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Past Year</div>
          <div class="font-w700 display-3 mb-4">
            {{ currentYearUsage | numberFormat }} <span class="font-w600 font-size-h1">kg CO<sub>2</sub>e</span>
          </div>
          <PctDiff :usage="currentYearUsage" :prev-usage="prevYearUsage">Compared to previous year</PctDiff>
          <!-- <div class="font-size-h4 font-w500 text-muted">Usage this week has been slightly higher so far compared to last week.</div> -->
        </div>
        <div class="col">
          <AssetYearConsumption style="height: 325px" :period="yearGraph.period" :data="consumption.currentMonthly.data" units="CO2e" emissions />
        </div>
      </div>
    </div>
  </KioskSlide>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinnerLogo';
import KioskSlide from './KioskSlide';
import AssetWeeklyConsumption from '@/views/Machine/templates/charts/AssetWeeklyConsumption';
import AssetYearConsumption from '@/views/Machine/templates/charts/AssetYearConsumption';
import PctDiff from './components/PctDiff.vue';

export default {
  name: 'KioskEnergy',
  components: {
    AssetWeeklyConsumption,
    AssetYearConsumption,
    KioskSlide,
    PctDiff,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yesterday: moment().subtract(2, 'days').startOf('day')
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      asset: 'kioskLive/asset',
      consumption: 'kioskLive/consumption',
      loadingAction: 'kioskLive/loadingAction',
      loading: 'kioskLive/loading'
    }),
    currentWeekUsage() {
      const startDate = this.yesterday.clone().subtract(1, 'week');
      const endDate = this.yesterday;

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.emissions.total, 0);
    },
    prevWeekUsage() {
      const startDate = this.yesterday.clone().subtract(2, 'weeks');
      const endDate = this.yesterday.clone().subtract(1, 'week');

      const consumptionItems = this.consumption.currentHH.data.filter(item => moment(item.startDate).isBetween(startDate, endDate, 'day', '[]'));

      return consumptionItems.reduce((acc, item) => acc + item.emissions.total, 0);
    },
    currentYearUsage() {
      return this.consumption.currentMonthly.emissions.total;
    },
    prevYearUsage() {
      return this.consumption.previousMonthly.emissions.total;
    },
    weekGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'week').startOf('day'),
          endDate: this.yesterday
        }
      };
    },
    yearGraph() {
      return {
        period: {
          startDate: moment().subtract(1, 'year').startOf('day'),
          endDate: this.yesterday
        }
      };
    }
  }
};
</script>
<style lang="scss"></style>

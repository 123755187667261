<template>
  <Chart svg :option="options" />
</template>
<script>
import { merge } from 'lodash';
import moment from 'moment';
import Chart from '@/components/echarts/Chart';

import chartOptions from './options';

export default {
  components: {
    Chart
  },
  props: {
    period: {
      type: Object,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    units: {
      type: String,
      required: true
    },
    monthdays: {
      type: Boolean,
      default: false
    },
    compare: {
      type: Boolean,
      default: false
    },
    emissions: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    options() {
      const startDate = moment(this.period.startDate);
      const endDate = moment(this.period.endDate);

      // Must be monthly granularity data
      const yearArray = Array.from({ length: endDate.diff(startDate, 'months') + 1 }, (_, i) => startDate.clone().add(i, 'months'));

      const series = [];

      const monthSeries = yearArray.map((date, i) => {
        const monthData = this.data.filter(item => moment(item.startDate).isSame(date, 'month'));

        const item = {
          value: monthData.reduce((acc, item) => acc + (this.emissions ? item.emissions.total : item.consumption), 0)
        };

        if (i === yearArray.length - 1) {
          item.itemStyle = {
            color: '#6dc6cd'
          };
        }

        return item;
      });

      series.push({
        name: 'This month',
        type: 'bar',
        data: monthSeries,
        itemStyle: {
          borderRadius: [5, 5, 0, 0]
        }
      });

      if (this.compare) {
        const monthBeforeSeries = yearArray.map(date => {
          const monthBeforeDate = moment.utc(date).subtract(1, 'month');
          const monthData = this.data.filter(item => moment(item.startDate).isSame(monthBeforeDate, 'month'));

          const item = {
            value: monthData.reduce((acc, item) => acc + (this.emissions ? item.emissions.total : item.consumption), 0)
          };

          return item;
        });

        series.unshift({
          name: 'Last month',
          type: 'bar',
          data: monthBeforeSeries,
          itemStyle: {
            borderRadius: [5, 5, 0, 0]
          }
        });
      }

      const options = merge({}, chartOptions, {
        xAxis: {
          name: 'Day',
          type: 'category',
          data: yearArray.map(date => date.format('MMM YYYY')),
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: {
          type: 'value',
          name: this.units,
          splitNumber: 2
        },
        series,
        legend: {
          show: false
        }
      });

      return options;
    }
  }
};
</script>

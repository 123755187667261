var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KioskSlide', {
    attrs: {
      "title": "Carbon Emissions",
      "sub-title": "Carbon emissions summary for ".concat(_vm.kiosk.asset.siteName),
      "hide": _vm.hideSlide
    }
  }, [_c('div', {
    staticClass: "p-5 border-bottom"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Past Week")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.currentWeekUsage)) + " "), _c('span', {
    staticClass: "font-w600 font-size-h1"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('PctDiff', {
    attrs: {
      "usage": _vm.currentWeekUsage,
      "prev-usage": _vm.prevWeekUsage
    }
  }, [_vm._v("Compared to previous week")]), _c('div', {
    staticClass: "font-size-h4 font-w500 text-muted"
  }, [_vm._v(" This is similar to driving a petrol car "), _c('strong', [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.currentWeekUsage / 100, 1)) + " miles")]), _vm._v(" or boiling the kettle "), _c('strong', [_vm._v(_vm._s(_vm._f("numberFormat")(_vm.currentWeekUsage / 0.05)) + " times")]), _vm._v(". ")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('AssetWeeklyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.weekGraph.period,
      "data": _vm.consumption.currentHH.data,
      "units": "CO2e",
      "weekdays": "",
      "compare": "",
      "emissions": ""
    }
  })], 1)])], 1), _c('div', {
    staticClass: "p-5"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Past Year")]), _c('div', {
    staticClass: "font-w700 display-3 mb-4"
  }, [_vm._v(" " + _vm._s(_vm._f("numberFormat")(_vm.currentYearUsage)) + " "), _c('span', {
    staticClass: "font-w600 font-size-h1"
  }, [_vm._v("kg CO"), _c('sub', [_vm._v("2")]), _vm._v("e")])]), _c('PctDiff', {
    attrs: {
      "usage": _vm.currentYearUsage,
      "prev-usage": _vm.prevYearUsage
    }
  }, [_vm._v("Compared to previous year")])], 1), _c('div', {
    staticClass: "col"
  }, [_c('AssetYearConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.yearGraph.period,
      "data": _vm.consumption.currentMonthly.data,
      "units": "CO2e",
      "emissions": ""
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }